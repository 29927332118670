var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "退货明细",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "search-bar"
  }, [_c('form', {
    attrs: {
      "action": "/"
    }
  }, [_c('van-search', {
    attrs: {
      "placeholder": "请输入客户名称",
      "clearable": ""
    },
    on: {
      "search": _vm.handleSearch,
      "clear": _vm.handleSearch
    },
    model: {
      value: _vm.listQuery.name,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "name", $$v);
      },
      expression: "listQuery.name"
    }
  })], 1)]), _c('div', {
    staticClass: "app-main-search-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-list', {
    ref: "list",
    staticClass: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.loadData
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.list, function (order) {
    return _c('van-cell', {
      key: order.id,
      staticClass: "order",
      attrs: {
        "title": order.num,
        "value": "\uFFE5".concat(order.amount),
        "label": order.client.name,
        "is-link": "",
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toAfterGoodsView(order.id);
        }
      }
    });
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }